import React, { Fragment, useEffect, useState } from "react";
import "./analytics.css";
import { getUserWallets } from "../../middleware/api";
import { BalanceModified } from "../../types/types";
import AnalyticsAccount from "./analytics-account";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Analytics = () => {
	const [wallet, setWallet] = useState<BalanceModified[]>([]);
	const [total, setTotal] = useState<number>(0);
	const [blocked, setBlocked] = useState<number>(0);
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);

	const accessToken = new URLSearchParams(window.location.search).get("token") || "";

	const initData = async () => {
		if (accessToken != undefined && accessToken != "") {
			const response = await getUserWallets(accessToken);
			setTotal(response.totalAmount.toFixed(3));
			setBlocked(response.blockedAmount);
			setWallet(response.balances);
		} else {
			setError(!error);
		}
	};

	useEffect(() => {
		initData();
	}, []);

	return (
		<Fragment>
			<HelmetProvider>
				<Helmet>
					<style>
						{`
						body {
    						background-color: #ffffff;
    						font-weight: 400;
    						font-family: 'Fira Sans', sans-serif;
						}
					`}
					</style>
				</Helmet>
			</HelmetProvider>
			{!error ? (
				<div className={"analytics-wrapper"}>
					<div className={"analytics-header"}>
						<h6 className={"total-alert"}>available</h6>
						<h2 className={"total-amount"}>{total}</h2>
						<span className={"blocked-amount"}>blocked {blocked}</span>
					</div>
					<div className={"analytics-main"}>
						{wallet.map((balance) => (
							<AnalyticsAccount balance={balance} isExpanded={isExpanded} setIsExpanded={setIsExpanded} key={wallet.indexOf(balance)}/>
						))}
					</div>
					<div className={"analytics-between"} ></div>
				</div>
			) : (
				<div className={"analytics-wrapper"}>
					<span>You should authorize to get access to wallets.</span>
				</div>
			)}
		</Fragment>
	);
};

export default Analytics;
