import React, {ChangeEvent, FormEvent, Fragment, useState} from "react";
import "./login.css";
import {Button, IconButton, InputAdornment, TextField} from "@mui/material";
import {VisibilityOffOutlined, VisibilityOutlined} from "@mui/icons-material";
import {authorize, checkMFCode} from "../../middleware/api";
import {Helmet, HelmetProvider} from "react-helmet-async";

export const Login = () => {
	const [email, setEmail] = useState<string>("");
	const [password, setPassword] = useState<string>("");

	const [mFCode, setMFCode] = useState<string>("");
	const [isCode, setIsCode] = useState<boolean>(false);

	const [stateStart, setStateStart] = useState<string>("");

	const [error, setError] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>("");

	const [isLogin, setIsLogin] = useState<boolean>(false);

	const userId = new URLSearchParams(window.location.search).get("token") ? atob(new URLSearchParams(window.location.search).get("token")!) : "";

	const disableButtonLogin = !(email.includes("@") && password.length > 6);

	const disableButtonMF = !(mFCode.length >= 6);

	const [showPassword, setShowPassword] = useState(false);

	const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
	};

	const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value);
	};

	const handleMFCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
		setMFCode(e.target.value);
	};

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsCode(false);
		setError(false);
		setErrorMsg("");
		await authorize(
			userId,
			email,
			password,
			"",
			setEmail,
			setPassword,
			setError,
			setErrorMsg,
			setIsCode,
			setStateStart
		);
	};

	const handleSubmitMF = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setError(false);
		setErrorMsg("");
		setIsLogin(false);
		await checkMFCode(
			userId,
			mFCode,
			stateStart,
			email,
			password,
			setMFCode,
			setError,
			setErrorMsg,
			setIsLogin
		);
	};

	if (isLogin) {
		return (

			<Fragment>
				<HelmetProvider>
					<Helmet>
						<style>
							{`
						body {
    						background-color: #1C8EF9;
    						font-weight: 400;
    						font-family: 'Fira Sans', sans-serif;
						}
					`}
						</style>
					</Helmet>
				</HelmetProvider>
				<div className={"background-container-login"}>
					<div className={"login-wrapper"}>
						<h1>Redirecting...</h1>
					</div>
				</div>
			</Fragment>
		);
	}

	if (isCode) {
		return (
			<Fragment>
				<HelmetProvider>
					<Helmet>
						<style>
							{`
						body {
    						background-color: #1C8EF9;
    						font-weight: 400;
    						font-family: 'Fira Sans', sans-serif;
						}
					`}
						</style>
					</Helmet>
				</HelmetProvider>
				<div className={"background-container-login"}>
					<div className={"login-wrapper"}>
						<form onSubmit={handleSubmitMF}>
							<h1 className={"login-item"}>Enter 2FA code</h1>
							<div className={"login-item"}>
								<TextField
									autoFocus={true}
									size="medium"
									className={"login-item"}
									id={"2faCode"}
									label={"2FA Code"}
									type="number"
									value={mFCode}
									fullWidth
									onChange={handleMFCodeChange}
								/>
							</div>
							{error ? (
								<div className={"login-item"}>
									<span className={"login-error"}>{errorMsg}</span>
								</div>
							) : (
								""
							)}
							<div className={"login-item"}>
								{disableButtonMF ? (
									<Button
										className={"login-button"}
										type="submit"
										disabled={disableButtonMF}
									>
										Submit
									</Button>
								) : (
									<Button
										className={"login-button"}
										type="submit"
										variant={"contained"}
										disabled={disableButtonMF}
									>
										Submit
									</Button>
								)}
							</div>
							<div className={"login-alert"}>
							<span className={"login-alert-text"}>
								This site is the property of Free2ex
							</span>
							</div>
						</form>
					</div>
				</div>
			</Fragment>
		);
	}

	return (
		<Fragment>
			<HelmetProvider>
				<Helmet>
					<style>
						{`
						body {
    						background-color: #1C8EF9;
    						font-weight: 400;
    						font-family: 'Fira Sans', sans-serif;
						}
					`}
					</style>
				</Helmet>
			</HelmetProvider>
			<div className={"background-container-login"}>
				<div className={"login-wrapper"}>
					<form onSubmit={handleSubmit}>
						<h1 className={"login-item"}>Sign In</h1>
						<div className={"login-item"}>
							<TextField
								autoFocus={true}
								size="medium"
								className={"login-item"}
								id={"email"}
								label={"E-mail"}
								value={email}
								fullWidth
								onChange={handleEmailChange}
							/>
						</div>
						<div className={"login-item"}>
							<TextField
								size="medium"
								className={"login-item"}
								id={"password"}
								label={"Password"}
								value={password}
								type={showPassword ? "text" : "password"}
								fullWidth
								onChange={handlePasswordChange}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end"
											>
												{showPassword ? (
													<VisibilityOffOutlined />
												) : (
													<VisibilityOutlined />
												)}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</div>
						{error ? (
							<div className={"login-item"}>
								<span className={"login-error"}>{errorMsg}</span>
							</div>
						) : (
							""
						)}
						<div className={"login-item"}>
							{disableButtonLogin ? (
								<Button
									className={"login-button"}
									type="submit"
									disabled={disableButtonLogin}
								>
									Submit
								</Button>
							) : (
								<Button
									className={"login-button"}
									type="submit"
									variant={"contained"}
								>
									Submit
								</Button>
							)}
						</div>
						<div className={"login-alert"}>
						<span className={"login-alert-text"}>
							This site is the property of Free2ex
						</span>
						</div>
					</form>
				</div>
			</div>
		</Fragment>
	);
};
