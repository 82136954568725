import React from "react";
import { useTelegram } from "../hooks/useTelegram";

const { tg } = useTelegram();

export const getUserWallets = async (accessToken: string) => {
	return await fetch("/api/wallet", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			accessToken: accessToken,
		}),
	}).then((data) => {
		return data.json();
	});
};

export const authorize = async (
	userId: string,
	email: string,
	password: string,
	state: string,
	setEmail: React.Dispatch<React.SetStateAction<string>>,
	setPassword: React.Dispatch<React.SetStateAction<string>>,
	setError: React.Dispatch<React.SetStateAction<boolean>>,
	setErrorMsg: React.Dispatch<React.SetStateAction<string>>,
	setIsCode: React.Dispatch<React.SetStateAction<boolean>>,
	setStateStart: React.Dispatch<React.SetStateAction<string>>
) => {
	const response = await fetch("/api/authorize", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			userId: userId,
			email: email,
			password: password,
			state: state,
		}),
	}).then((data) => {
		return data.json();
	});
	if (response.code != undefined) {
		setEmail("");
		setPassword("");
		setError(true);
		setErrorMsg(response.code);
	} else if (response.error.innerErrors[0].code === "MULTI_FACTOR_REQUIRED") {
		setIsCode(true);
		setStateStart(response.error.innerErrors[0].details.state);
	} else {
		setEmail("");
		setPassword("");
		setError(true);
		setErrorMsg(response.error.innerErrors[0].message);
	}
};

export const checkMFCode = async (
	userId: string,
	mfCode: string,
	state: string,
	email: string,
	password: string,
	setMFCode: React.Dispatch<React.SetStateAction<string>>,
	setError: React.Dispatch<React.SetStateAction<boolean>>,
	setErrorMsg: React.Dispatch<React.SetStateAction<string>>,
	setIsLogin: React.Dispatch<React.SetStateAction<boolean>>
) => {
	const response = await fetch("/api/mfCode", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			userId: userId,
			state: state,
			code: mfCode,
			email: email,
			password: password,
		}),
	}).then((data) => {
		return data.json();
	});
	if (response.error != undefined) {
		setMFCode("");
		setError(true);
		setErrorMsg(response.error.innerErrors[0].message);
	} else {
		setIsLogin(true);
		await sendMenu(userId);
		setTimeout(() => {
			tg.close();
		}, 500);
	}
};

export const sendDate = async (userId: string, dateRange: string, command: string) => {
	if (command.includes("activities")) {
		command = "/activities-date";
	} else if (command.includes("transactions")) {
		command = "/transactions-date";
	}
	await fetch("/api/sendDate", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			message: {
				from: {
					id: userId,
				},
				text: command,
				dateRange: dateRange,
				entities: [
					{
						offset: 0,
						length: 5,
						type: "bot_command",
					},
				],
			},
		}),
	});
	setTimeout(() => {
		tg.close();
	}, 300);
};

export const sendMenu = async (userId: string) => {
	return await fetch("/api/sendMenu", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			message: {
				from: {
					id: userId,
				},
				text: "/menu-secretKey-webapp",
				entities: [
					{
						offset: 0,
						length: 5,
						type: "bot_command",
					},
				],
			},
		}),
	});
};
