import dayjs, { Dayjs } from "dayjs";
import "./date-picker.css";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { Fragment, useEffect, useState } from "react";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Button, StyledEngineProvider } from "@mui/material";
import { sendDate } from "../../middleware/api";
import { Helmet, HelmetProvider } from "react-helmet-async";

const DatePickerComponent = () => {
	const [dateFrom, setDateFrom] = useState<Dayjs | null>(
		dayjs(
			new Date(
				new Date().getFullYear(),
				new Date().getMonth() - 3,
				new Date().getDate()
			)
		)
	);
	const [dateTo, setDateTo] = useState<Dayjs | null>(dayjs(new Date()));
	const disableButton = dateFrom == null || dateTo == null;

	const userId = new URLSearchParams(window.location.search).get("token")
		? atob(new URLSearchParams(window.location.search).get("token")!)
		: "";

	const command = new URLSearchParams(window.location.search).get("command") || "";

	const handleSubmit = async () => {
		await sendDate(userId, dateFrom?.toDate() + "|" + dateTo?.toDate(), command);
	};

	useEffect(() => {
		const keyDownHandler = (e: KeyboardEvent) => {
			if (e.key === "Enter") {
				e.preventDefault();
				handleSubmit();
			}
		};
		addEventListener("keydown", keyDownHandler);
		return () => {
			removeEventListener("keydown", keyDownHandler);
		};
	}, []);

	return (
		<Fragment>
			<HelmetProvider>
				<Helmet>
					<style>
						{`
						body {
    						background-color: #1C8EF9;
    						font-weight: 400;
    						font-family: 'Fira Sans', sans-serif;
							}
						`}
					</style>
				</Helmet>
			</HelmetProvider>
			<div className={"background-container-date"}>
				<div className={"date-picker-wrapper"}>
					<h1 className={"date-picker-item"}>Pick date</h1>
					<StyledEngineProvider injectFirst>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<div className={"date-picker-date"}>
								<MobileDatePicker
									inputFormat="DD/MM/YYYY"
									label="Date from"
									value={dateFrom}
									showToolbar={false}
									onChange={(newValue) => {
										setDateFrom(newValue);
									}}
									renderInput={(params) => <TextField {...params} />}
								/>
								<MobileDatePicker
									inputFormat="DD/MM/YYYY"
									label="Date to"
									value={dateTo}
									showToolbar={false}
									onChange={(newValue) => {
										setDateTo(newValue);
									}}
									renderInput={(params) => <TextField {...params} />}
								/>
							</div>
						</LocalizationProvider>
					</StyledEngineProvider>
					<div className={"date-picker-item"}>
						{disableButton ? (
							<Button
								className={"date-picker-button"}
								type="submit"
								disabled={disableButton}
							>
								Submit
							</Button>
						) : (
							<Button
								className={"date-picker-button"}
								type="submit"
								variant={"contained"}
								onClick={handleSubmit}
								onKeyDown={handleSubmit}
							>
								Submit
							</Button>
						)}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default DatePickerComponent;
