import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import "./analytics.css";
import {BalanceModified, Pocket} from "../../types/types";

interface Props {
    balance: BalanceModified;
    isExpanded: boolean;
    setIsExpanded: Dispatch<SetStateAction<boolean>>;
}

const AnalyticsAccount = (props: Props) => {
    const [needExpand, setNeedExpand] = useState<boolean>(false);
    const [pockets, setPockets] = useState<Pocket[]>();
    const [extraPockets, setExtraPockets] = useState<Pocket[]>();
    const balance = props.balance;

    useEffect(() => {
        if(props.balance.pockets.length <= 4) {
            setPockets(balance.pockets)
        } else {
            setPockets(props.balance.pockets.slice(0,4));
            setExtraPockets(props.balance.pockets.slice(4, props.balance.pockets.length));
            setNeedExpand(true);
        }
    },[])

    const handleClick = () => {
        props.setIsExpanded(!props.isExpanded);
    }

    return (
        <div>
            <span className={"analytics-row-item"}>Account: {balance.type}</span>
            {pockets?.map((pocket) => (
                <div className={"analytics-row-item"} key={JSON.stringify(pocket)+balance.accountNumber}>
                    <span>{pocket.currency}</span>
                    <span>{pocket.amount}</span>
                </div>
            ))}
            {needExpand && !props.isExpanded ? (
                <div className={"analytics-row-item-expand"} onClick={handleClick}>More...</div>
            ) : (<div onClick={handleClick}>
                {extraPockets?.map((pocket) => (
                    <div className={"analytics-row-item"} key={JSON.stringify(pocket)+balance.accountNumber}>
                        <span>{pocket.currency}</span>
                        <span>{pocket.amount}</span>
                    </div>
                ))}
            </div>)}
        </div>
    );
};

export default AnalyticsAccount;